.h1 {
  @apply text-[50px]  leading-[73px]  tracking-tighter;
}

.h2 {
  @apply text-[40px]  leading-[60px]  tracking-tighter;
}

.h3 {
  @apply text-[30px]  leading-[49px];
}

.h4 {
  @apply text-[20px]  leading-[37px] tracking-tight;
}

.h5 {
  @apply text-[20px]  leading-[29px];
}

.h6 {
  @apply text-[18px]  leading-[24px];
}

.body {
  @apply text-[16px]  leading-[20px];
}
.paragraph {
  @apply text-[16px]  leading-[29px] tracking-tight;
}
.caption {
  @apply text-[14px]  leading-[17px];
}
.small {
  @apply text-[12px]  leading-[15px];
}
.tiny {
  @apply text-[10px]  leading-[12px];
}

.header-menu-text {
  @apply flex justify-center items-center cursor-pointer transition duration-300 text-[length:--headerMenuTextSize] font-[--headerMenuFontWeight] text-[color:--headerMenuTextColor] hover:text-[color:--headerMenuHoverTextColor] whitespace-nowrap ;
}

.title-h1{
  @apply  text-[length:--TitleH1TextSize] font-[--TitleH1FontWeight] text-[color:--TitleH1TextColor]  leading-[--TitleH1LineHeight] ;
}


   
.heading-h2{
  @apply  text-[length:--HeadingH2TextSize] font-[--HeadingH2FontWeight] text-[color:--HeadingH2TextColor]  leading-[--HeadingH2LineHeight] ;
}
.text-input {
  @apply text-lg  ;
}

  //  font-title_font #{!important}

.description-paragraph{
  @apply text-[length:--DescriptionsParagraphTextSize] font-[--DescriptionsParagraphFontWeight] text-[color:--DescriptionsParagraphTextColor]  leading-[--DescriptionsParagraphLineHeight];
}

   
.description-caption{
  @apply text-[length:--DescriptionsCaptionTextSize] font-[--DescriptionsCaptionFontWeight] text-[color:--DescriptionsCaptionTextColor]  leading-[--DescriptionsCaptionLineHeight];
}



@screen md {
  .h1 {
    @apply text-[60px]  leading-[73px];
  }

  .h2 {
    @apply text-[50px]  leading-[60px];
  }
  .h3 {
    @apply text-[40px]  leading-[49px];
  }
  .h4 {
    @apply text-[30px]  leading-[37px];
  }
  .h5 {
    @apply text-[24px]  leading-[29px];
  }
  .h6 {
    @apply text-[20px]  leading-[24px];
  }
  .paragraph {
    @apply text-[18px]  leading-[29px] tracking-tight;
  }
  .body {
    @apply text-[16px]  leading-[20px];
  }
}

// Tradly Buttons
.btn {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-5 py-3;
}

.btn-sm {
  @apply px-4 py-1.5;
}

.btn-xs {
  @apply px-2 py-0.5;
}

.btn,
.btn-lg,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded-button leading-5 shadow-sm transition duration-150 ease-in-out;
}

// Badge
.badge-error {
  @apply px-2 py-0.5 font-medium text-sm text-error inline-flex items-center justify-center  bg-red-600  bg-opacity-20  rounded-button leading-5 shadow-sm;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply text-sm text-gray-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 outline-none shadow-sm;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-400;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-primary border border-gray-300;
}

[dir='rtl'] select {
  background-position: left 0.5rem center;
}

.prose {
  max-width: 100% !important;
}

// Sub menu pattern
/* since nested groupes are not supported we have to use 
       regular css for the nested dropdowns 
    */
li > ul {
  display: none;
}

li:hover > button {
  @apply bg-[#f2f4f4] text-primary;
}
li:hover > ul {
  display: block;
}

li > button svg {
  transform: rotate(-90deg);
}

li:hover > button svg {
  transform: rotate(-270deg);
}
