          

.account-card{
  @apply  w-full min-h-[100px] bg-[var(--accountCardBackgroundColor)] max-w-[var(--accountCardMaxWidth)] rounded-[var(--accountCardBorderRadius)] overflow-hidden cursor-pointer shadow-account_card  transition ease-in-out duration-500 group-hover:bg-[var(--accountCardHoverBackgroundColor)] group-hover:shadow-account_card_hover mr-[--accountCardMarginRight] ml-[--accountCardMarginLeft] mt-[--accountCardMarginTop] mb-[--accountCardMarginBottom] pl-[--accountCardPaddingLeft] pr-[--accountCardPaddingRight] pt-[--accountCardPaddingTop] pb-[--accountCardPaddingBottom]
}

 
.account-card-image{
    @apply rounded-[--accountCardImageBorderRadius] h-[--accountCardImageHeight] w-[--accountCardImageWidth] overflow-hidden
}

 
.account-card-title {
    @apply text-[length:var(--accountCardAccountNameTextSize)] text-[color:var(--accountCardAccountNameColor)]   font-[--accountCardAccountNameTextFontWeight] leading-[--accountCardAccountNameTextLineHeight];
}
 
.account-card-owner {
    @apply text-[length:var(--accountCardOwnerNameTextSize)] text-[color:var(--accountCardOwnerNameColor)]   font-[--accountCardOwnerNameTextFontWeight] leading-[--accountCardOwnerNameTextLineHeight];
}
   
.account-card-location {
    @apply  text-[length:var(--accountCardLocationTextSize)] text-[color:var(--accountCardLocationColor)]   font-[--accountCardLocationTextFontWeight] leading-[--accountCardOLocationTextLineHeight]
}
   
 
.account-card-direction-button {
    @apply  bg-[--accountCardDirectionButtonBackground] bg-[image:--accountCardDirectionButtonBackgroundImage] h-[--accountCardDirectionButtonHeight] w-[--accountCardDirectionButtonWidth] max-w-[--accountCardDirectionButtonMaxWidth] min-h-[--accountCardDirectionButtonMinHeight] rounded-[--accountCardDirectionButtonRounded]  text-[length:var(--accountCardDirectionButtonTextSize)] text-[color:var(--accountCardDirectionButtonTextColor)]   font-[--accountCardDirectionButtonFontWeight] leading-[--accountCardDirectionButtonLineHeight] mr-[--accountCardDirectionMarginRight] ml-[--accountCardDirectionMarginLeft] mt-[--accountCardDirectionMarginTop] mb-[--accountCardDirectionMarginBottom] pl-[--accountCardDirectionPaddingLeft] pr-[--accountCardDirectionPaddingRight] pt-[--accountCardDirectionPaddingTop] pb-[--accountCardDirectionPaddingBottom]
}
   
.account-card-category-button {
    @apply  bg-[--accountCardCategoryButtonBackground] bg-[image:--accountCardCategoryButtonBackgroundImage] h-[--accountCardCategoryButtonHeight] w-[--accountCardCategoryButtonWidth] max-w-[--accountCardCategoryButtonMaxWidth] min-h-[--accountCardCategoryButtonMinHeight] rounded-[--accountCardCategoryButtonRounded]  text-[length:var(--accountCardCategoryButtonTextSize)] text-[color:var(--accountCardCategoryButtonTextColor)]   font-[--accountCardCategoryButtonFontWeight] leading-[--accountCardCategoryButtonLineHeight] mr-[--accountCardCategoryMarginRight] ml-[--accountCardCategoryMarginLeft] mt-[--accountCardCategoryMarginTop] mb-[--accountCardCategoryMarginBottom] pl-[--accountCardCategoryPaddingLeft] pr-[--accountCardCategoryPaddingRight] pt-[--accountCardCategoryPaddingTop] pb-[--accountCardCategoryPaddingBottom]
}
   
 