
.btn-red-fill {
  @apply font-medium text-sm text-white hover:text-red-600 inline-flex items-center justify-center border border-red-600 bg-red-600 hover:bg-transparent rounded-button leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn-red-outline {
  @apply font-medium text-sm text-red-600 hover:text-white inline-flex items-center justify-center border border-red-600 bg-transparent hover:bg-red-600 rounded-button  leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn-primary-outline {
  @apply px-2 py-1.5 md:px-3 md:py-2 font-medium text-sm text-primary hover:text-white inline-flex items-center justify-center border border-primary bg-transparent hover:bg-primary rounded-button leading-5 shadow-sm transition duration-300 ease-in-out;
}

.btn-primary-fill {
  @apply px-2 py-1.5 md:px-3 md:py-2 font-medium text-sm text-white hover:text-primary inline-flex items-center justify-center gap-2 border border-primary bg-primary hover:bg-transparent rounded-button leading-5 shadow-sm transition duration-300 ease-in-out;
}

.btn-warning-outline {
  @apply font-medium text-sm text-warning hover:text-white inline-flex items-center justify-center gap-2 border border-warning bg-transparent hover:bg-warning rounded-button leading-5 shadow-sm transition duration-300 ease-in-out;
}
.btn-disabled {
  @apply px-2 py-1.5 md:px-3 md:py-2  font-medium text-sm text-gray-500 bg-gray-200  cursor-not-allowed inline-flex items-center justify-center border border-gray-300 rounded-button leading-5 shadow-sm transition duration-300 ease-in-out;
}

 