.tooltip {
    position: relative;
}


/* top */
.tooltip .top {
    min-width: 100%;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);
    padding: 20px 20px;
    color: #fff;
    background-color: var(--info_color);
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.174);
    display: none;
    text-align: left;
}


.tooltip:hover .top {
    display: block;
}

.tooltip .top i {
    position: absolute;
    top: -11px;
    left: 10%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
}


.tooltip .top i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: var(--info_color);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.214);
}

/* Bottom */
.tooltip .bottom {
    min-width: 100%;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 20px 20px;
    color: #fff;
    background-color: var(--info_color);
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.174);
    display: none;
    text-align: left;
}

.tooltip:hover .bottom {
    display: block;
}



.tooltip .bottom i {
    position: absolute;
    bottom: -10px;
    left: 10%;
    margin-left: -12px;
    /* width: 24px;
    height: 12px; */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--info_color);
}

/* .tooltip .bottom i::after {
    content: '';
    position: absolute;
    
} */



/* top_right */
.tooltip .top_right {
    width: 300px;
    bottom: -10px;
    right: 0%;
    transform: translate(0%, 100%);
    padding: 20px 20px;
    color: #fff;
    background-color: var(--info_color);
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.174);
    display: none;
    text-align: left;

    [dir='rtl'] & {
    
        text-align: right;
        }
}


.tooltip:hover .top_right {
    display: block;
}

 

@media only screen and (max-width: 500px) {
.tooltip .top_right {
        width: 95vw;
         
    }
}
/* top_left */
.tooltip .top_left {
    width: 260px;
    bottom: -10px;
    left: 0%;
    transform: translate(0%, 100%);
    padding: 20px 20px;
    color: #fff;
    background-color: var(--info_color);
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.174);
    display: none;
    text-align: left;

    [dir='rtl'] & {
    
        text-align: right;
        }
}


.tooltip:hover .top_left {
    display: block;
}

 

@media only screen and (max-width: 500px) {
.tooltip .top_left {
        width: 95vw;
         
    }
}



/* top_hover */
.tooltip .top_hover {
    direction: ltr;
    width: 300px;
    bottom: -2px;
    left: 50%;
    transform: translate(-20%, 100%);
    padding: 20px 20px;
    color: #fff;
    background-color: var(--info_color);
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.174);
    display: none;
    text-align: left;
    
    [dir='rtl'] & {
        
        right:50%;
            transform: translate(20%, 100%);
        }
}


.tooltip:hover .top_hover {
    display: block;
}

 
@media only screen and (max-width: 500px) {
    .tooltip .top_hover {
            width: 80vw;
          
            transform: translate(-20vw, 100%);
            left: 0%;
    [dir='rtl'] & {

        right: 0%;
      
    }
            
        }
}

 