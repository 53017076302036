
* {
  font-family: var(--primary_font) ;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: var(--primary_color) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* OTP Box */
.otp-input input {
  height: 100%;
  width: 100%;
  background: transparent;
  color: white ;
  font-size: 20px !important;
  font-weight: 600;
  width: 100% !important;
  margin: 0px !important;
  outline: none;
  border: 1px solid white;
  border-radius: 48px !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.otp-input input:focus {
  outline: none;
  border: 1px solid white;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.swiper-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@keyframes ldio-uqpdw6hds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-uqpdw6hds div {
  position: absolute;
  animation: ldio-uqpdw6hds linear 1s infinite;
  background: var(--primary_color);
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.ldio-uqpdw6hds div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--primary_color);
}
.loadingio-spinner-spinner-xedb3iwiaw {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-uqpdw6hds {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-uqpdw6hds div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.form-control {
  background-color: white !important;
  color: black;
  width: 100% !important;
  border: none !important;
  height: 48px !important;
  border-radius: 6px !important;
  border: 1px solid white !important;
  padding-left: 55px !important;
}
.form-control:focus {
  outline: none !important;
}

.flag-dropdown {
  border: none !important;
  background-color: transparent !important;
  padding-left: 12px !important;
}
.react-tel-input .country-list {
  background-color: var(--primary_color) !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1ecec !important;
  color: var(--primary_color) !important;
}

.rc-slider-track {
  background-color: var(--primary_color) !important;
}
.rc-slider-handle {
  border-color: var(--primary_color) !important;
}
.rc-slider-handle:focus {
  border-color: var(--primary_color) !important;
}
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}

.dropdown-container {
  border: none !important;
  border-bottom: 2px solid rgba(229, 231, 235) !important;
  border-radius: 0px !important;
  transition: all 700ms ease-in-out;
  background: transparent !important;
}
.filter_multi_select > .dropdown-container {
  border:2px solid rgba(229, 231, 235) !important;
   border-radius: 0px !important;
  transition: all 700ms ease-in-out;
}

.dropdown-container:focus-within {
  border-color: var(--primary_color) !important;
  box-shadow: none !important;
}

// .editor {
//   .ProseMirror {
//     min-height: 180px;
//   }
// }

.ProseMirror {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0 !important;
  }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  left: -25px !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}




/* for  Composer task list and item */
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

ul[data-type="taskList"] p {
  margin: 0;
}

ul[data-type="taskList"] li {
  display: flex;
}

ul[data-type="taskList"] li>label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
  margin-top: 0;
  margin-bottom: 0;
}
ul[data-type="taskList"] li>label >[type='checkbox'],
[type='radio']{
  vertical-align:text-top;
}

ul[data-type="taskList"] li>div {

  margin-top: 0;
  margin-bottom: 0;
}

ul[data-type="taskList"] li>div {
  flex: 1 1 auto;
}




/*  */
.ProseMirror table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ProseMirror table td,
.ProseMirror table th {
  border: 2px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

.ProseMirror table td>*,
.ProseMirror table th>* {
  margin-bottom: 0;
}

.ProseMirror table th {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
}

.ProseMirror table .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.ProseMirror table .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.ProseMirror table p {
  margin: 0;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}


// 
.custom-icon-gradient {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: transparent !important;
  background: linear-gradient(to right, rgb(101, 84, 192) 25%, rgb(0, 184, 217) 25%, rgb(0, 184, 217) 50%, rgb(255, 153, 31) 50%, rgb(255, 153, 31) 75%, rgb(222, 53, 11) 75%);
}


// animated text
.animate-character {
background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%)   ;
  background-size: auto auto  ;
  background-clip: border-box  ;
  background-size: 200% auto !important;
  color: #fff;
  background-clip: text ;
  text-fill-color: transparent ;
  -webkit-background-clip: text ;
  -webkit-text-fill-color: transparent ;
  animation: textclip 2s linear infinite ;
  display: inline-block ;
  font-size: 190px;
 }


@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

// <----animated text end


// react-datepicker-wrapper
.react-datepicker-wrapper{
  width: 100% ;
}